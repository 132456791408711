.Container {
  display: grid;
  height: 100%;
}

.GridContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: 1rem;
}

.IconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 2/6;
}

.ContentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-column: 6/10;
}

.IconContainer {
  height: 20rem;
  width: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.IconCircle {
  background-color: var(--primary-color-10);
  height: 100%;
  width: 100%;
  position: absolute;
  border-radius: 50%;
}

.Description {
  font-size: 95%;
  color: var(--text-muted, #777777);
  margin-bottom: 1rem;
}
