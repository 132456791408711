.VideoContainer {
  position: fixed;
  width: 750px;
  height: 430px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999;
  background-color: white;
  border: 2px solid white;
  border-radius: 4px;
}

.VideoContainer iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.Overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5) !important;
  inset: 0px;
  z-index: 1000;
}
