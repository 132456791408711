.InputContainer {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.Search {
  padding-left: 3rem !important;
}

.Lens {
  position: absolute;
  left: 1rem;
}
