.Container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  padding: 3rem;
  background-color: var(--main-bg);
  width: 100%;
  border-radius: var(--border-radius);
}

.UpgradeText {
  color: var(--text-muted) !important;
}

.PlanButtonGroup {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}
