.TilesContainer {
  display: grid;
  gap: 25px;
  grid-template-columns: repeat(auto-fit, 270px);
}

.Tile {
  background-color: var(--white);
  padding: 0.5rem;
  display: flex;
  align-items: center;
  border-radius: 8px;
  gap: 0.5rem;
  color: var(--black);
  box-shadow: 0 2px 4px 0 rgba(#000, 4%);
  position: relative;
  cursor: pointer;
}

.LogoContainer {
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
}

.Status {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}

.Badge {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  font-size: 10px;
  border-radius: 4px;
  padding: 0.1rem 0.2rem;
  letter-spacing: 0.1px;
}

.PremiumBadge {
  @extend .Badge;

  background-color: #DD9700;
  color: var(--white);
}
