.Container {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  background-color: var(--white, #fff);
  height: 100%;
  border-radius: var(--border-radius, 6px);
  padding: 2rem 1rem;
  margin-top: 1rem;
}

.MainContent {
  grid-column: 1/8;
}

.BackButton {
  cursor: pointer;
}

.SidebarContent {
  grid-column: 10/13;
  background-color: #fafafa;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
}

.AnswerContent {
  padding-left: 1.7rem;
}

.TitleInput {
  width: 65% !important;
}

.ButtonContainer {
  display: flex;
  align-items: center;
}

.SaveAdd {
  margin-left: 1rem;
}

.DeleteButton {
  margin-left: auto;
  padding-right: 0;
  min-width: 0;
}

.DeleteButton:hover {
  color: var(--danger-color);
}

.AnswerVisibilityContainer {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.AnswerVisibilty {
  grid-column: 1/3;
}

.VisibilityGroup {
  padding-top: 2rem;
  grid-column: 3/6;
}

.Title {
  display: flex;
  align-items: center;
  gap: 1rem;
}
