.Active {
  color: var(--text-success);
}

.Container {
  margin-top: 2rem;
}

.Deactivated {
  color: var(--text-danger);
}

.Invited {
  color: var(--text-muted);
}
