.Container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100%;
}

.Content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Cover {
  width: 100%;
  background-image: url(./cover.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #615d53;
  color: var(--white);
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CoverContent {
  width: 26rem;
}

.CoverDisplay {
  border-radius: 20px 20px 0 0;
  background-image: url(./laptop.png);
  background-repeat: no-repeat;
  background-size: contain;
  height: 18rem;
  z-index: 1;
  position: relative;
}

.CoverInfo {
  padding: 2rem;
  background-color: #4e4a3f;
  margin-top: -2.5rem;
  z-index: 0;
  border-radius: 0 0 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
}

.CoverTitle {
  opacity: 0.5;
  font-weight: bold;
}

.CoverText {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.ContentCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  position: relative;
}

.SlackButton {
  padding: 0.7rem 3rem;
  background-color: #2c2c2c;
  border: 1px solid #ededed;
  color: var(--white);
  box-shadow: 0 5px 10px 0 hsla(223, 8%, 36%, 0.1);
  cursor: pointer;
  border-radius: var(--border-radius);
  font-weight: bold;
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: 1rem;
  text-decoration: none;
}

.Logo {
  position: absolute;
  top: -14rem;
}

.NewCustomer {
  position: absolute;
  bottom: -14rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.TryButton {
  font-weight: normal !important;
}

@media only screen and (max-width: 1440px) {
  .CoverContent {
    width: 22rem;
  }

  .CoverDisplay {
    height: 14rem;
  }

  .CoverInfo {
    margin-top: -0.5rem;
    gap: 1rem;
  }

  .CoverText {
    font-size: 18px;
  }
}
