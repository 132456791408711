.Container {
  margin: 1rem 0;
  padding-top: 1rem;
  border-top: 1px solid #eee;
}

.CheckboxTreeContainer {
  margin-top: 1rem;
}

.SyncButtonContainer {
  text-align: right;
}
