.Container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  height: var(--topbar-height, 3.2rem);
  padding: 0.5rem 1rem;
  box-shadow: var(--topbar-shadow, 0px 2px 4px 0px rgba(0, 0, 0, 6%));
  background-color: var(--topbar-bg-color, #fff);
}

.BrandContainer {
  background-color: var(--sidebar-bg-color, #191d20);
  width: var(--sidebar-width, 230px);
}

.WorkspaceItem {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  border-left: 1px solid #f1f0f0;
  padding-left: 1rem;
  cursor: pointer;
}

.Actions {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.IconAction {
  border-radius: 50%;
  height: 25px;
  width: 25px;
  background-color: #f8f8f8;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 6%);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.IconAction:hover {
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 8%);
}
