.Container {
  display: grid;
  height: 100%;
  grid-template-columns: 350px 1fr;
  gap: 1rem;
}

.Cover {
  background-image: url("./images/dashboard-free-bg.png");
  border-radius: var(--border-radius);
  background-size: cover;
  color: var(--white) !important;
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
}

.CoverPro {
  background-image: url("./images/dashboard-pro-bg.png");

  .PlanBadge {
    color: #D0962E;
  }
}

.CoverEnterprise {
  background-image: url("./images/dashboard-enterprise-bg.png");

  .PlanBadge {
    color: #D0962E;
  }
}

.Content {
  padding: 4rem;
}

.Heading {
  color: inherit;
  line-height: 1.2;
  margin: 0;
}

.Tuts {
  margin-top: auto;
}

.TutImage {
  cursor: pointer;
}

.CardsContainer {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.Card {
  border-radius: var(--border-radius);
  background-color: var(--white);
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 4%);
  padding: 1.5rem;
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.Card:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 4%);
}

.CardContent {
  flex: 1;
}

.CardTitle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.SubText {
  font-size: 13px;
}

.CardGroup {
  background-color: #fff;

  .Card:not(:last-child) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .Card + .Card {
    border-top: 1px solid #eee;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.CardGroupTitle {
  margin: 1rem 1.5rem 0.2rem;
}

.PlanLabel {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
}

.PlanButton {
  font-weight: normal !important;
  font-size: 12px !important;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.ActionButton {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.Pricing {
  margin-top: 4rem;
}

.PlanBadge {
  background-color: var(--white);
  color: var(--black);
  padding: 0.2rem 0.4rem;
  border-radius: 4px;
  font-weight: 700;
  color: #5d74a8;
}

.PlanButtonContainer {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
}

.PlanComparisionButton {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  border-color: var(--white) !important;
  background-color: transparent !important;
  color: var(--white) !important;
  padding: 0.3rem 0.5rem !important;
  font-weight: normal;
}
