.Container {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  padding: 1rem;
  background-color: var(--white);
  gap: 1.5rem;
  flex: 1;
  margin-top: 1rem;
}

.Title {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.SideContent {
  grid-column: 1/4;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.MainContent {
  grid-column: 4/13;
  border-left: 1px solid var(--border-color-light);
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.LogoContainer {
  border-radius: 10px;
  width: 6rem;
  height: 6rem;
  box-shadow: 0px 0px 1px 0px rgb(0 0 0 / 40%);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.BrandImage {
  max-width: 50%;
  max-height: 50%;
}

.Description {
  font-size: 94%;
  color: var(--text-muted);
  margin-bottom: 1rem;
}
