.GridContainer {
  display: grid;
  place-items: center;
  height: 100%;
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.Container {
  grid-column: 3/4;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  text-align: center;
  color: var(--primary-color);
}
