.CallbackURL {
  background-color: red;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  background-color: var(--main-bg);
  border-radius: var(--border-radius);
  margin-top: 3rem;
  gap: 1rem;
}

.CopyButton {
  margin-left: auto;
  cursor: pointer;
}
