.Title {
  font-weight: 700;
}

.Form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.ButtonContainer {
  display: flex;
  align-items: center;
}

.DisconnectButton {
  margin-left: auto;
  font-weight: normal !important;
}
