.Container {
  display: grid;
  grid-template-columns: repeat(14, minmax(0, 1fr));
  background-color: var(--white);
  padding: 3rem 2rem;
  border-radius: var(--border-radius);
}

.ConfigContainer {
  grid-column: 1/11;
}

.Cover {
  grid-column: 12/15;
  background-color: var(--primary-color-10);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.ConfigContainer.Loading .Controls{
  opacity: 0.7;
  pointer-events: none;
}

.Config {
  display: flex;
  align-items: center;
}

.Config:not(:last-child) {
  border-bottom: 1px solid #f1f0f0;
}

.Content {
  padding: 1rem 0;
}

.Controls {
  margin-left: auto;
}
