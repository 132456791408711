:root {
  // colors
  --white: #ffffff;
  --black: #000000;

  // color yellow
  --yellow: 46, 100%, 47%;
  --yellow-100: hsla(var(--yellow), 100%);
  --yellow-90: hsla(var(--yellow), 90%);
  --yellow-80: hsla(var(--yellow), 80%);
  --yellow-60: hsla(var(--yellow), 60%);
  --yellow-40: hsla(var(--yellow), 40%);
  --yellow-20: hsla(var(--yellow), 20%);
  --yellow-10: hsla(var(--yellow), 10%);
  --yellow-5: hsla(var(--yellow), 5%);

  // green
  --green: 160, 100%, 35%;
  --green-100: hsla(var(--green), 100%);

  // color red
  --red-10: #ffe5e5;
  --red-50: #fc9ea0;
  --red-100: #ff4447;

  // theme colors
  --text-color: #191d20;
  --text-muted: #777777;
  --text-success: var(--green-100);
  --text-black: #000000;
  --text-danger: var(--red-100);
  --text-alert: var(--red-10);

  // danger variants
  --danger-color: var(--red-100);
  --danger-color-100: var(--red-100);

  // primary variants
  --primary-color: var(--yellow-100);
  --primary-color-100: var(--yellow-100);
  --primary-color-90: var(--yellow-90);
  --primary-color-80: var(--yellow-80);
  --primary-color-60: var(--yellow-60);
  --primary-color-40: var(--yellow-40);
  --primary-color-20: var(--yellow-20);
  --primary-color-10: var(--yellow-10);

  // font
  --base-font-size: 14px;
  --body-font-size: 14px;
  --font-size-sm: 12px;
  --base-font-family: Arial, Helvetica, sans-serif;

  // body
  --main-bg: #f9f9f9;

  // button
  --button-padding-y: 0.6rem;
  --button-padding-x: 0.9rem;
  --button-padding-y-sm: 0.325rem;
  --button-padding-x-sm: 0.75rem;
  --secondary-button-color: var(--black);
  --secondary-button-font-weight: normal;
  --success-button-font-weight: normal;

  // inputs
  --input-padding-y: var(--button-padding-y);
  --input-padding-x: var(--button-padding-x);
  --input-border-color: #bbbbbb;

  // borders
  --border-radius: 6px;
  --border-color-light: #eee;

  /**
   * Application theme
   */
  --topbar-height: 3.2rem;
  --topbar-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 6%);
  --topbar-bg-color: var(--white);
  --sidebar-bg-color: #191d20;
  --sidebar-width: 230px;
  --sidebar-text-color: var(--white);
  --sidebar-item-active-bg-color: #2e363c;
  --sidebar-sub-item-active-bg-color: #22282d;
  --sidebar-item-active-icon-color: var(--primary-color);

  // shadows
  --tile-shadow: 0px 2px 4px rgba(0, 0, 0, 4%);
  --input-focus-ring-color: var(--primary-color);

  // table
  --table-head-bg-color: #adabaa;
}
