.Container {
  display: flex;
  font-size: 13px;
  margin-top: auto;
  background-color: var(--main-bg);
  width: 100%;
  padding: var(--button-padding-y) var(--button-padding-x);
}

.Status {
  margin-left: 1rem;
  color: var(--text-muted);
}

.UninstallButton {
  margin-left: auto;
  padding: 0;
  font-weight: normal;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
