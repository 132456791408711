.Container {
  display: grid;
  grid-template-columns: var(--sidebar-width, 230px) 1fr;
  margin-top: 2rem;
}

.Nav {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.NavLink {
  text-decoration: none;
}

.NavLink::before {
  display: block;
  content: attr(title);
  font-weight: bold;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.NavLinkActive {
  font-weight: bold;
}

.NavItem {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.Content {
  border: 1px solid;
  border-image: linear-gradient(to bottom, #ddd, #fff) 0 0 0 100%;
  padding-left: 1rem;
}

.Sidebar {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  padding-right: 3rem;
}

.SidebarContent {
  border-top: 1px solid #DCDCDC;
  padding-top: 1.5rem;
}
