$width: 5rem;
$height: 1rem;

.Container {
  display: grid;
  height: 100%;
  place-items: center;
}

.Loader {
  width: $width;
  height: $height;
  border-radius: 40px;
  color: var(--primary-color, #00b177);
  border: 2px solid;
  position: relative;
}

.Loader::before {
  content: "";
  position: absolute;
  margin: 2px;
  width: 25%;
  top: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
  background: currentColor;
  animation: wobble 1s infinite linear;
}

@keyframes wobble {
  50% {
    left: 100%;
    transform: translateX(calc(-100% - 4px));
  }
}
