.TableContainer {
  margin-top: -2.3rem;
}

.Table {
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;
  border-radius: var(--border-radius, 6px);
  box-shadow: 0 0 3px 0 rgb(0 0 0 / 20%);
  margin-bottom: 1rem;
}

.Cell {
  padding: 0.5rem;
  text-align: inherit;
  text-align: -webkit-match-parent;
}

.Th {
  @extend .Cell;
  background-color: #eee;
}

.Td {
  @extend .Cell;
}

.SiteLink {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: inherit;
  text-decoration: none;
}

.NoSites {
  text-align: center;
}

.AddSiteContainer {
  margin: 1rem 0;
}

.AddSiteForm {
  display: flex;
  gap: 1rem;
}

.SearchSite {
  width: 50%;
}
