$input-padding-y: var(--input-padding-y, 0.6rem);
$input-padding-x: var(--input-padding-x, 0.9rem);
$input-font-size: var(--base-font-size, 1rem);
$trix-toolbar-height: 35px;
$trix-toolbar-offset: var(--input-padding-y, 0.6rem);

.TrixContainer {
  position: relative;
}

.TrixContent {
  border: 1px solid #979797;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: var(--border-radius, 6px);
  line-height: 1.5;
  text-overflow: ellipsis;
  font-size: $input-font-size;
  padding: $input-padding-y $input-padding-x;
  border-color: var(--input-border-color, #bbb);
  font-size: 1rem;
  min-height: 22rem !important;
  padding-bottom: calc($trix-toolbar-height + $trix-toolbar-offset + $input-padding-y);
  outline: none;

  &:focus:not(.Invalid) {
    border-color: var(--input-focus-ring-color);
  }

  &.Invalid {
    border-color: var(--danger-color);
  }

  .attachment__progress {
    display: none;
  }

  ul {
    padding: revert;
  }
  h1 {
    font-size: 1.5rem;
    font-weight: 600;
  }
  img {
    max-width: 100% !important;
    height: revert !important;
  }
  ul li {
    list-style: revert;
  }
  blockquote {
    margin-left: 1rem;
    border-left: 5px solid #eee;
    padding-left: 1rem;
  }
  strong {
    font-weight: 600;
  }
  figcaption {
    display: none;
  }
  pre {
    white-space: normal;
  }
}

:global {
  trix-toolbar[id="trix-editor"] {
    height: $trix-toolbar-height;
    position: absolute;
    bottom: $trix-toolbar-offset;
    left: $trix-toolbar-offset;

    .trix-button-group {
      margin-bottom: 0 !important;
      border: 0 !important;
    }

    .trix-button-group:not(:first-child) {
      margin-left: 0 !important;
    }

    .trix-button-row {
      background: #f4f5f5;
      padding: 5px;
      border-radius: 10px;
    }

    .trix-button {
      border: 0 !important;
    }
  }
}
