.Overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4) !important;
  inset: 0px;
  z-index: 1000;
}

.Container {
  position: fixed;
  background-color: var(--white, #fff);
  z-index: 1001;
  width: 450px;
  height: 100%;
  top: 0;
  right: 0;
}

.Header {
  position: relative;
  height: 50px;
  display: flex;
  place-items: center;
  padding-left: 1.5rem;
}

.Close {
  display: inline-flex;
  width: 50px;
  height: 50px;
  position: absolute;
  left: -50px;
  top: 0;
  background-color: var(--white, #fff);
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: var(--border-radius, 6px);
  cursor: pointer;
}

.Content {
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: calc(100% - 50px);
}
