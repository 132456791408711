.TipContainer {
  margin-top: 2rem;
}

.TipTitle {
  font-weight: bold;
  margin: 1rem 0;
}

.TipContent {
  list-style: inherit;
  font-size: 13px;
  color: var(--text-muted, #777777);
  padding: 0 0 0 1rem;
}

.Tip {
  margin-bottom: 0.7rem;
}

.Tip::before {
  display: inline-block;
  content: "-";
  width: 1em;
  margin-left: -1em;
}
