.MainContainer {
  height: 100%;
  display: grid;
  grid-template-columns: var(--sidebar-width, 230px) 1fr;
  background-color: var(--main-bg);
}

.Content {
  padding: 2rem 3rem;
  height: calc(100% - var(--topbar-height));
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.PageContainer {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
