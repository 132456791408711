.Container {
  margin-top: 2rem;
}

.SearchInputContainer {
  width: 20rem;
  margin-right: auto;
}

.AnswerTable th:not(:first-child):not(:last-child) {
  width: 150px;
}

.NoFilterResults {
  text-align: center;
  margin: 5rem 0;
}
