.Container {
  position: relative;
  display: grid;
  height: 100%;
  place-items: center;
  background-color: #fff;
}

.Logo {
  position: absolute;
}
