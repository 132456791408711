$active-bg-color: var(--sidebar-item-active-bg-color);
$active-subitem-bg-color: var(--sidebar-sub-item-active-bg-color);
$active-icon-color: var(--sidebar-item-active-icon-color);

.MenuContainer {
  width: 100%;
  flex: 1 1 0%;
  padding: 2rem 0.75rem;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  a {
    text-decoration: none;
  }
}

.Divider {
  margin: 0.75rem 0;
  height: 1px;
  opacity: 10%;
}

.MenuItemContainer {
  display: block;
}

.Icon {
  color: #5d6062;
  display: flex;
  width: 1em;
}

.MenuItem {
  display: flex;
  align-items: center;
  border-radius: 0.375rem;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  color: var(--white, #fff);

  &:hover {
    background-color: $active-bg-color;
  }

  &.Active {
    background-color: $active-bg-color;
    font-weight: 700;

    .Icon {
      color: $active-icon-color;
    }
  }
}

.SubMenu {
  margin-top: 0.5rem;

  .MenuItem {
    margin-bottom: 2px;

    &.Active {
      background-color: $active-subitem-bg-color;
    }
  }
}

.MenuLabel {
  margin-left: 0.5rem;
  display: flex;
  flex: 1;
}

.ArrowContainer {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.Hidden {
  display: none;
}
