.TableContainer {
  margin-top: -3.3rem;
}

.Table {
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;
  border-radius: var(--border-radius, 6px);
  box-shadow: 0 0 3px 0 rgb(0 0 0 / 20%);
  margin-bottom: 1rem;
}

.Cell {
  padding: 0.5rem;
  text-align: inherit;
  text-align: -webkit-match-parent;
}

.Th {
  @extend .Cell;
  background-color: #eee;
}

.Td {
  @extend .Cell;
}
