.Header {
  display: flex;
  align-items: center;
}

.ModalBody {
  padding: 1rem 0;
}

.HeaderContent {
  flex: 1;
}

.Close {
  padding: 0.25rem;
  cursor: pointer;
}
