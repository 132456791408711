@import "./reset";
@import "./theme";
@import "./sanitize";

html,
body {
  font-size: 14px;
}

body {
  font-family: var(--base-font-family, Arial, Helvetica, sans-serif);
  color: var(--text-color, #000);
}

#__root {
  height: 100%;
  min-width: 1200px;
}

#__page-root {
  height: 100%;
}
