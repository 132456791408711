.AccountLabel {
  display: flex;
}

.ChangeButton {
  margin-left: auto;
}

.SaveButton {
  margin-top: 1rem;
}

.Status {
  width: 50%;
}
